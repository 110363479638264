/*
 * @Author: chaixiaobu 13817847080@163.com
 * @Date: 2024-05-07 14:33:47
 * @LastEditors: chaixiaobu 13817847080@163.com
 * @LastEditTime: 2024-08-22 13:45:26
 * @FilePath: \vue3-vant-mobile\src\store\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createPinia } from 'pinia'
export * from './modules/user'
export * from './modules/wechat'

const pinia = createPinia()
export default pinia
