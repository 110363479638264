/*
 * @Author: chaixiaobu 13817847080@163.com
 * @Date: 2024-08-14 10:34:03
 * @LastEditors: chaixiaobu 13817847080@163.com
 * @LastEditTime: 2024-08-22 13:41:14
 * @FilePath: \chai-activity-h5\src\store\modules\user\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      name: '',
    }
  },
  getters: {
    nameLength: (state) => state.name.length,
  },
  actions: {
    updateName(name: string) {
      this.name = name
    },
  },
})
export default useUserStore
