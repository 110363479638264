/*
 * @Author: chaixiaobu 13817847080@163.com
 * @Date: 2024-08-22 11:26:07
 * @LastEditors: chaixiaobu 13817847080@163.com
 * @LastEditTime: 2024-08-23 11:22:56
 * @FilePath: \chai-activity-h5\src\store\modules\wechat\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { defineStore } from 'pinia'
import { useWechat } from '@/hooks'
export const useWechatStore = defineStore('wechat', {
  state: () => {
    return {
      isWechat: false,
      longitude: '',
      latitude: '',
      isWechatBrowser: false,
    }
  },
  actions: {
    updateLatlng({ latitude, longitude, isWechatBrowser }: any) {
      this.latitude = latitude
      this.longitude = longitude
      this.isWechatBrowser = isWechatBrowser
    },
    async initWechat(isInclude: boolean) {
      const { ready, isWechatBrowser } = await useWechat(isInclude)
      if (!isWechatBrowser) {
        return Promise.resolve()
      }
      const location = await ready()
      this.updateLatlng({ ...location, isWechatBrowser })
      this.isWechat = true
    },
  },
})
